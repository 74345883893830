import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import Tag from "../components/v2023/UI/Tag/tag"
import ReactMarkdown from "react-markdown"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import CustomerLogos from "../components/v2023/CustomerLogos/customer-logos"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Opinions from "../components/v2023/Opinions/opinions"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import LastBlogPosts from "../components/v2023/LastBlogPosts/last-blog-posts"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import { Player } from "@lottiefiles/react-lottie-player"


const IndexPage = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page
    const solutions = data.industries.edges
    const industries = solutions.filter(item => item.node.industries_category.id === 1 || item.node.industries_category.id === 4).sort((a, b) => a.node.orderId - b.node.orderId)
    const sectors = data.sectors.edges
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const pathNews = {
        "en": "/news",
        "es": "/es/actualidad"
    }
    const pathSectors = {
        "en": "/industries",
        "es": "/es/industrias",
    }

    const [showAlert, setShowAlert] = useState(true)
    const [platformClass, setPlatformClass] = useState("showed")
    const [selectedIndexSolution, setSelectedIndexSolution] = useState(0)
    const [platformSelectedIndex, setPlatformSelectedIndex] = useState(0)
    const [platformSelectedMobile, setPlatformSelectedMobile] = useState(page.platform_items[0].id)
    //const [platformSection, setPlatformSection] = useState(null)
    //const [onPlatformSection, setOnPlatformSection] = useState(false)

    /*useEffect(() => {
        setPlatformSection(document.getElementById('platform-section'))
    }, [platformSection])

    useLayoutEffect(() => {
        const handleWheel = (e) => {
            if (onPlatformSection) {
                if (e.deltaY < 0 && platformSelectedIndex > 0) {
                    setPlatformSelectedIndex(platformSelectedIndex - 1)
                }

                if (e.deltaY > 0 && platformSelectedIndex < (page.platform_items.length - 1)) {
                    setPlatformSelectedIndex(platformSelectedIndex + 1)
                }

                e.preventDefault()
                e.stopPropagation()

                console.log('mousewheel', e.deltaY, platformSelectedIndex)
            }
        }

        window.addEventListener('mousewheel', handleWheel, {passive:false})

        return () => window.removeEventListener(`mousewheel`, handleWheel)
    }, [platformSelectedIndex, onPlatformSection])

    useScrollPosition((currentPosition, event) => {
        if (platformSection) {
            const currentTop = Math.abs(currentPosition.currentPosition.y) + (document.body.offsetHeight / 2)
            const elementTop = platformSection.offsetTop + (platformSection.offsetHeight / 2)

            if (currentTop >= (elementTop - 20) && currentTop <= (elementTop + 20)) {
                console.log('preventDefault stopPropagation')
                document.body.style.overflow = 'hidden'
                setOnPlatformSection(true)
                //document.body.style.overflow = ''
            }
        }
    }, [platformSection, platformSelectedIndex, onPlatformSection])*/

    const changePlatform = async (index) => {
        setPlatformClass("hidden")

        await new Promise(r => setTimeout(r, 300))

        setPlatformSelectedIndex(index)
        setPlatformClass("showed")
    }


    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                {showAlert && <div className="alert">
                    <div className="alert__body">
                        {/*<i className="icon-star"></i>
                        <span>{page.news.actualidad.title}</span>
                        {page.news.button_blank &&
                            <a href={`${pathNews[lang]}/${page.news.actualidad.seo_url}`} className="button button--secondary button--secondary--ghost button--xs"
                               target="_blank" rel="noreferrer">{page.news.button_text} <i className="icon-arrow-right"></i></a>}
                        {!page.news.button_blank &&
                            <Link to={`${pathNews[lang]}/${page.news.actualidad.seo_url}`} className="button button--secondary button--secondary--ghost button--xs">
                                {page.news.button_text} <i className="icon-arrow-right"></i>
                            </Link>}*/}
                        <i className="icon-star"></i>
                        <span>{page.news.text}</span>
                        {page.news.button_blank &&
                        <a href={page.news.button_url} className="button button--secondary button--secondary--ghost button--xs"
                           target="_blank" rel="noreferrer">{page.news.button_text} <i className="icon-arrow-right"></i></a>}
                        {!page.news.button_blank &&
                        <Link to={page.news.button_url} className="button button--secondary button--secondary--ghost button--xs">
                            {page.news.button_text} <i className="icon-arrow-right"></i>
                        </Link>}
                    </div>
                    <div className="alert__action">
                        <button className="button-icon" onClick={() => setShowAlert(!showAlert)}>
                            <i className="icon-close"></i>
                        </button>
                    </div>
                </div>}

                <div className="main__section main__section--50 main__section--50--lg highlight-section">
                    <div className="container">
                        <div className="container__50">
                            <h1 className="merriweather">
                                {page.title}
                            </h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <div className="tag-group-align-left">
                                {page.products.map((item, index) => 
                                    <Link to={`${item.url}`}>
                                        <Tag key={index} tag={{name: item.text}} size="lg" />
                                    </Link>
                                )}
                            </div>
                            <div className="button__wrapper">
                                <LinkButton button={page.talk_expert_button} className={"button button--xl"} />
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <Player src={page.header_anim_file.localFile.publicURL} autoplay={true} loop={true} />
                        </div>
                    </div>
                </div>

                <CustomerLogos />

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.trust_us_title}</h2>
                        <div className="grid-sm-3">
                            {page.trust_us_items.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl">{item.amount + item.symbol}</div>
                                    <span>{item.description}</span>
                                </div>
                            })}
                        </div>
                        <LinkButton button={page.trust_us_button} className={"link--big"} extras={<i className="icon-arrow-right"></i>} />
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--green tile--green-complex">
                            <div className="tile__body">
                                <div className="tile__body__title">
                                    <h2>{page.overview_title}</h2>
                                    <ReactMarkdown className="title__description" children={page.overview_description} rehypePlugins={[rehypeRaw]} />
                                    <StaticImage
                                        src={"../new/resources/home/overview.png"}
                                        alt="overview"
                                        placeholder="blurred"
                                        title="overview"
                                        className="title__image"
                                    />
                                </div>
                                <div className="tile__body__products">
                                    <div className="products__list">
                                        {page.overview_items.map((item, index) => {
                                            return <div className="products__list__product" key={index}>
                                                <div className="product__title">
                                                    <div className="product__title__icon">
                                                        <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} />
                                                    </div>
                                                    <div className="product__title__name">
                                                        {!item.url && <span className="link--big">{item.title}</span>}
                                                        {item.url && <Link to={`${item.url}`} className="link--big">{item.title}<i className="icon-arrow-right"></i></Link>}
                                                    </div>
                                                </div>
                                                <div className="product__description">
                                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="platform-section" className="main__section main__section--100 color-section  purple-dark">
                    <div className="container">
                        <h2>{page.platform_title}</h2>
                        <div className="stepper-group">
                            <div className="stepper hidden-md">
                                <div className="stepper__bar"></div>
                                <div className="stepper__items">
                                    {page.platform_items.map((item, index) => {
                                        return <div className="stepper__items__item" key={index} onClick={() => changePlatform(index)}>
                                            <div className={`item__dot ${index === platformSelectedIndex ? "item__dot--pulse" : ""}`}></div>
                                            <div className="item__title">{item.title}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="stepper__content">
                                <div className={`stepper__content__desktop hidden-md ${platformClass}`}>
                                    <div>
                                        <Player
                                            src={page.platform_items[platformSelectedIndex].anim_file.localFile.publicURL}
                                            autoplay={true}
                                            keepLastFrame={true}
                                            loop={true} />
                                    </div>
                                    <div>
                                        <div className="tag tag--highlight ghost">{page.platform_items[platformSelectedIndex].tag}</div>
                                        <div className="container__text">
                                            <ReactMarkdown children={page.platform_items[platformSelectedIndex].description} rehypePlugins={[rehypeRaw]} />
                                            <LinkButton button={page.platform_items[platformSelectedIndex].button} className={"button button--lg button--ghost"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="show-md">
                                    <div className="item-list">
                                        {page.platform_items.map((item, index) => {
                                            return <div className={`item item--ghost ${item.id === platformSelectedMobile ? "item--active" : ""}`} key={index}>
                                                <div className="item__header" onClick={() => setPlatformSelectedMobile(platformSelectedMobile !== item.id ? item.id : -1)}>
                                                    <div className="item__header__title">{item.title}</div>
                                                    <i className="icon-chevron-down"></i>
                                                </div>
                                                <div className="item__body">
                                                    <div className="tag tag--highlight tag--highlight--md">{item.tag}</div>
                                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                                    <LinkButton button={page.platform_items[platformSelectedIndex].button} className={"button button--lg"} />
                                                    <div class="m-t-24">
                                                        {item.id === platformSelectedMobile && <Player
                                                            src={item.anim_file.localFile.publicURL}
                                                            autoplay={true}
                                                            keepLastFrame={true}
                                                            loop={true}
                                                        />}
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="main__section main__section--60-40">
                    <div className="container">
                        <div className="container__60">
                            <div className="button-card-list">
                                <div className="button-card-list__cards">
                                    {sectors.map((item, index) => {
                                        return <Link to={`${pathSectors[lang]}/${item.node.url}`} className="button-card" key={index}>
                                            <div className="button-card__icon">
                                                <GatsbyImage image={getImage(item.node.icon.localFile)} alt={item.node.icon.alternativeText} title={item.node.icon.alternativeText} />
                                            </div>
                                            <div className="button-card__info">
                                                <h6>{item.node.tag}</h6>
                                            </div>
                                        </Link>
                                    })}

                                </div>
                                <Link to={`${page.industries_button.url}`} className="link--big">
                                    {page.industries_button.text}
                                    <i class="icon-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="container__40">
                            <h2>{page.what_can_you_do_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.what_can_you_do_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md">
                    <div className="container">
                        <div className="container__50">
                            <ReactMarkdown children={page.compliance_title_underline} rehypePlugins={[rehypeRaw]} />
                            <div className="container__text">
                                <ReactMarkdown children={page.compliance_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <LinkButton button={page.compliance_button} className={"button button--xl"} />
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.compliance_image.alternativeText} image={getImage(page.compliance_image.localFile)} />
                        </div>
                    </div>
                </div>
                <Opinions lang={lang} />
                <ReadyToTalk lang={lang} />
                <LastBlogPosts lang={lang} />
                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout>

    )
}

export default IndexPage

export const indexPageQuery = graphql`
    query ($langKey: String) {
        page: strapi2023HomeV2 (locale: {eq: $langKey}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            news {
                # actualidad {
                #     title
                #     seo_url
                # }
                button_text
                button_blank
                text
                button_url
            }
            title
            description
            products {
                text
                url
                blank
            }
            talk_expert_button {
                text
                url
                blank
            }
            header_anim_file {
                localFile {
                    publicURL
                }
            }
            trust_us_title
            trust_us_items {
                amount
                symbol
                description
            }
            trust_us_button {
                text
                url
                blank
            }
            overview_title
            overview_description
            overview_items {
                title
                description
                url
                blank
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 32
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            platform_title
            platform_items {
                id
                title
                tag
                description
                anim_file {
                    localFile {
                        publicURL
                    }
                }
                button {
                    text
                    url
                    blank
                }
            }
            industries_button {
                text
                url
                blank
            }
            what_can_you_do_title
            what_can_you_do_description
            compliance_title
            compliance_title_underline
            compliance_description
            compliance_button {
                text
                url
                blank
            }
            compliance_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                            breakpoints: [200, 400, 608]
                        )
                    }
                }
            }
        }
        industries: allStrapiIndustry ( filter: { locale: { eq: $langKey }, show_in_home: {eq: true} } )  {
            edges {
                node {
                    name
                    tag
                    url
                    orderId
                    icon_new {
                        name
                        alternativeText
                        localFile{
                            publicURL
                        }
                    }
                    industries_category {
                        id
                        url
                        title
                    }
                }
            }
        }
        sectors: allStrapiSector ( filter: { locale: { eq: $langKey } , show_home: { eq: true } } ) {
            edges {
                node {
                    url
                    tag
                    icon {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 48
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                    breakpoints: [27]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
